/* AuditorApproval.css */

.approval-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  min-height: 100vh;
  box-sizing: border-box;
}

.previous-header {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.submission-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  table-layout: fixed;
}

.submission-table th, .submission-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  word-wrap: break-word;
  align-items: center;
}

.submission-table th {
  background-color: #f2f2f2;
  color: #333;
}

.submission-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.submission-item.approved {
  background-color: #d4edda;
}

.submission-item.rejected {
  background-color: #f8d7da;
}

.submission-table a {
  color: #007bff;
  text-decoration: none;
}

.submission-table a:hover {
  text-decoration: underline;
}

button {
  margin: 4px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
}

.approve-button {
  background-color: #28a745;
}

.reject-button {
  background-color: #dc3545;
}

@media (max-width: 768px) {
  .submission-table {
    font-size: 14px;
    overflow-x: auto;
  }

  .submission-table th, .submission-table td {
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .submission-table th, .submission-table td {
    display: block;
    width: 100%;
    box-sizing: border-box;
    text-align: right;
    position: relative;
  }

  .submission-table th::before, .submission-table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding: 0 10px;
    background: #f4f4f4;
    font-weight: bold;
    text-align: left;
  }

  .submission-table td::before {
    content: "";
  }
}

/* AuditorApproval.css */

/* Existing styles here */

.note-button {
  background-color: #007bff;
}

.note-button:hover {
  background-color: #0056b3;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 500px;
}

.modal-content h3 {
  margin-top: 0;
}

.modal-close-button {
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  margin-top: 20px;
}

.modal-close-button:hover {
  background-color: #c82333;
}

@media (max-width: 768px) {
  .modal-content {
    width: 95%;
  }
}
