/* ClaimProfile.css */
.equal-height-row {
    display: flex;
}

.equal-height-col {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px; /* Adds separation between the two rows */
}

.equal-height-col .ant-card {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.vertical-center-card {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.center-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.center-row {
    align-items: center;
}
