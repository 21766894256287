.browse-container {
    padding: 10px 20px;
    background-color: #f0f2f5;
    min-height: 100vh;
  }
  
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }
  
  .cardContent {
    background-color: #fff;
  }
  