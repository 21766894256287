/* style.css */

body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f4f6f9;
    color: #333;
    margin: 0;
    padding: 0;
}

.feedback-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.feedback-info {
    margin-bottom: 30px;
    text-align: center;
}

.feedback-container h2 {
    font-size: 32px;
    color: #2c3e50;
    margin-bottom: 15px;
    font-weight: 600;
}

.feedback-container h3 {
    font-size: 24px;
    color: #34495e;
    margin-top: 20px;
    margin-bottom: 10px;
}

.feedback-container p {
    font-size: 16px;
    line-height: 1.5;
    color: #555;
}

.feedback-container a {
    color: #007bff;
    text-decoration: none;
    font-weight: 500;
}

.feedback-container a:hover {
    text-decoration: underline;
}

.feedback-container ul {
    padding-left: 20px;
    list-style: disc;
}

.feedback-form {
    /* background-color: #f9f9f9; */
    padding: 20px;
    border-radius: 8px;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}

.form-group {
    margin-bottom: 20px;
}

.submit-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.feedback-container label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #333;
}

input[type="text"],
input[type="email"],
textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
    box-sizing: border-box;
    transition: border-color 0.3s;
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
    border-color: #059669;
    outline: none;
}

textarea {
    height: 150px;
    resize: none;
}

.button {
    display: inline-block;
    padding: 12px 20px;
    background-color: #059669;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.button:hover {
    background-color: #000000;
}

