/* Overall Container */
.faq-container {
    padding: 30px 20px;
    display: flex;
    justify-content: center;
  }
  
  /* FAQ Card Styling */
  .faq-card {
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    /* padding: 50px; */
    max-width: 900px;
    width: 100%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  /* Title Styling */
  .faq-title {
    text-align: center;
    font-size: 1rem;
    color: #2c3e50;
    font-weight: 100;
    margin-bottom: 40px;
  }
  
  /* Collapse Styling */
  .faq-collapse {
    background: transparent;
    border-radius: 15px;
  }
  
  .faq-panel {
    background: #f9f9f9;
    border-radius: 15px;
    border: none;
    margin-bottom: 15px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .faq-panel:hover {
    background-color: #f0f4f8;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }
  
  .faq-panel .ant-collapse-header {
    font-size: 1.25rem;
    color: #059669;
    font-weight: 600;
    transition: color 0.3s ease;
  }
  
  .faq-panel .ant-collapse-header:hover {
    color: #059669;
  }
  
  /* Paragraph Styling */
  .faq-paragraph {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #555;
    margin-top: 15px;
    transition: color 0.3s ease;
  }
  
  .faq-paragraph:hover {
    color: #333;
  }
  
  /* Contact Styling */
  .faq-contact {
    font-size: 1.15rem;
    text-align: center;
    color: #7f8c8d;
    margin-top: 30px;
  }
  
  .faq-link {
    color: #059669;
    font-weight: 600;
    text-decoration: none;
    transition: color 0.3s ease, text-decoration 0.3s ease;
  }
  
  .faq-link:hover {
    color: #000000;
    text-decoration: underline;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .faq-title {
      font-size: 1 rem;
    }
  }
  