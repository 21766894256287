/* AddAuditorForm.css */

.form-container {
    max-width: 850px;
    margin: auto;
    padding: 30px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .info-container {
    background-color: #f0f0f0; /* Light gray background */
    border: 1px solid #ccc; /* Light border */
    border-radius: 5px; /* Rounded corners */
    padding: 15px; /* Padding inside the container */
    margin-bottom: 15px; /* Margin at the bottom to separate from other elements */
    display: flex; /* Flexbox layout */
    justify-content: space-between; /* Align items in the center */
    align-items: center; /* Align items in the center */
  }
  
  .info-text {
    margin-right: 10px; /* Margin between text and close button */
  }
  
  .close-info-button {
    background-color: #059669;
    color: #fff;
    border: none;
    padding: 8px 15px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .close-info-button:hover {
    background-color: #000000;
  }

  .low-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .header {
    text-align: center;
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .subtitle {
    text-align: center;
    font-size: 18px;
    color: #555;
    margin-bottom: 30px;
  }
  
  .input-container {
    margin-bottom: 20px;
  }
  
  .label-style {
    display: block;
    margin-bottom: 8px;
    color: #666;
    font-size: 16px;
  }
  
  .input-style,
  textarea {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 6px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .input-style:focus,
  textarea:focus {
    border-color: #059669;
  }
  
  .checkbox-container {
    margin-bottom: 20px;
  }
  
  .checkbox-label-style {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #555;
  }
  
  .checkbox-style {
    margin-right: 10px;
  }
  
  .submit-button-style {
    background-color: #059669;
    color: #fff;
    padding: 12px 24px;
    font-size: 18px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button-style:hover {
    background-color: #000000;
  }
  