.title {
    display: block;
    font-size: 1.1rem;
    margin-bottom: 20px;
    border-bottom: 4px solid #05c796;
}

.rateCard{
    height: 200px;
}

.rateDate{
    color: #ccc;
    text-align: end;
}
