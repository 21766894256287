@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~antd/dist/antd.css';

/* Since we disabled preflight */
*,
::before,
::after {
  border-width: 0;
  border-style: solid;
  border-color: theme('borderColor.DEFAULT', currentColor);
}

.cardContent {
  box-shadow: 0 2px 6px 1px rgba(7,17,27,.1);
  margin-bottom: 24px;
}

/* .main:hover{
  transition: .3s all linear;
  box-shadow: 0 8px 16px 0 rgba(7,17,27,.1);
} */

.rateNumber{
  padding-left: 5px;
  color: gold;
  font-weight: bold;
}

/* .clearfix{
  overflow: hidden;
  clear: both;
} */

/* Beta features */
/* ::-webkit-scrollbar{
  width: 16px;
}

::-webkit-scrollbar-thumb{
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: content-box;
  background-color: #909090;
}

::-webkit-scrollbar-track{
  background-color: #f0f2f5;
} */
