:global
  .ant-list-item:first-child
    padding-top: 0

.description
  .rateDetail
    margin-bottom: 12px
  p
    color: black

  .extra
    margin-top: 16px
    line-height: 22px
    .createdAt
      text-align: right

.dashedDivider
  border-left: 1px dashed #ccc
  height: 100%
  position: absolute
  left: 0%
  top: 0
  @media (max-width: 768px)
    display: none

.ratingStats 
  margin-top: 20px
  text-align: center
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  color: grey

  .statsHeading 
    font-size: 20px
    margin-bottom: 10px

  .circularMeter 
    width: 100px
    height: 100px
    margin: auto

  .statsContainer 
    display: flex
    flex-direction: column
    align-items: center

    .statItem 
      font-size: 16px
      margin-bottom: 8px

      span 
        font-weight: bold
        margin-left: 10px
