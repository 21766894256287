.sitemap-container {
    padding: 30px 20px;
    display: flex;
    justify-content: center;
  }
  
  .sitemap-card {
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    width: 100%;
  }
  
  /* Title Styling */
  .sitemap-title {
    text-align: center;
    font-size: 1rem;
    color: #2c3e50;
    font-weight: 100;
    margin-bottom: 40px;
  }
 
  .sitemap-label {
    font-size: 1.25rem;
    font-weight: 600;
		margin-bottom: 0px;			
  }

  .sitemap-link {
    color: #059669;
    font-weight: 600;
    text-decoration: none;
  }
  
  .sitemap-link:hover {
    color: #000000;
    text-decoration: underline;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .sitemap-title {
      font-size: 1 rem;
    }
  }
  
