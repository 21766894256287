.headerCover
    background-color: #ccc
    height: 120px

.headerInfo
    position: relative
    .userAvatar
        position: absolute
        bottom: 0
        border: 4px solid #fff
        border-radius: 8px
    .userName
        font-size: 1.5rem
        font-weight: 500
        margin-bottom: 12px
