/* style.css */
@media (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }
  .hide-not-in-mobile{
    font-size: 1rem; /* This corresponds to text-lg */
    font-weight: 600; /* This corresponds to font-semibold */
    color: #000000; /* This corresponds to text-black */
  }
}

@media (min-width: 768px) {
  .hide-not-in-mobile {
    display: none;
  }
}

.align-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px; /* Optional: Add some space between elements */
}
