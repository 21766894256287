/* General styles for the discussion form */
.discussion-form {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: linear-gradient(to bottom right, #ffffff, #f0f0f0);
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #333;
  }

  .open-info-button {
    background-color: #059669; /* Green background */
    border: none;
    color: white; /* White text */
    padding: 9px 15px; /* Padding */
    text-align: center; /* Center text */
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 10px; /* Margin for spacing */
    cursor: pointer; /* Cursor style */
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s; /* Smooth background color transition */
  }
  
  .open-info-button:hover {
    background-color: #000000; /* Darker green when hovering */
  }
  
  .discussion-form h1 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  /* Form group styles */
  .form-group {
    margin-bottom: 20px;
  }
  
  input[type="text"],
  textarea {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }

  .radio-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .radio-group label {
    font-size: 1rem;
    color: #555;
    margin-right: 10px;
    }

  .radio-input {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    /* background-color: #059669; */
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border: 2px solid #059669;
    border-radius: 50%;
    transition: border-color 0.3s ease;
  }

  .timestamp {
    font-size: 0.8em;
    color: #777;
    text-align: right;
  }
  
  input[type="text"]:focus,
  textarea:focus {
    border-color: #059669;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
  }
  
  /* Submit button styles */
  button.submit-button {
    max-width: 500px;
    display: block;
    width: 100%;
    padding: 12px;
    background-color: #059669;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin: 0 auto; /* Center-align horizontally */
  }
  
  button.submit-button:hover {
    background-color: #000000;
    transform: scale(1.02);
  }
  
  /* Info container styles */
  .info-container {
    margin-top: 20px;
    padding: 15px;
    background: #f9f9f9;
    border-radius: 8px;
    border-left: 5px solid #059669;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  }
  
  .info-text {
    margin-bottom: 10px;
    font-size: 1rem;
    line-height: 1.6;
  }
  
  button.close-info-button {
    padding: 10px 20px;
    background-color: #059669;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-bottom: 30px;
  }
  
  button.close-info-button:hover {
    background-color: #000000;
    transform: scale(1.02);
  }
  
/* Comments container styles */
.comments-container {
    margin-top: 30px;
    padding: 20px;
    background: linear-gradient(to bottom right, #ffffff, #f9f9f9);
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .comments-list {
    list-style-type: none;
    padding: 0;
  }
  
  .comment {
    background-color: #f7f7f7;
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 10px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    border-left: 5px solid #059669;
    position: relative;
    overflow: hidden;
  }

  .comments-container {
    display: flex;
    flex-direction: column;
  }
  
  .row-container {
    display: flex;
    justify-content: space-between; /* Adjusts space between items */
    gap: 10px; /* Adjusts space between items */
  }
  
  .row-container > * {
    flex: 1; /* Each item takes equal width */
  }

  /* Styling for 'note' type comments */
.comment.note {
    border-left: 5px solid #856404; /* Dark yellow text */;
  }
  
  .comment::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    /* background: linear-gradient(to right, #007bff, #0056b3); */
  }
  
  .comment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .comment-type {
    font-size: 0.9rem;
    color: #999;
    margin-right: 10px;
    text-transform: uppercase;
    /* background-color: #f0f0f0; */
    padding: 5px 10px;
    border-radius: 8px;
  }
  
  .comment-header strong {
    font-size: 1.2rem;
    color: #059669;
  }
  
  .comment-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  .comment-content {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
  }
  
  .comment p {
    margin: 0;
    padding: 5px 0;
  }
  
  .comment p strong {
    display: block;
    font-size: 1.2rem;
    color: #333;
  }
  
  .comment p:not(:last-child) {
    margin-bottom: 10px;
  }
  
  .reply-button {
    background-color: #059669;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .reply-button:hover {
    background-color: #000000;
    transform: scale(1.05);
  }
  
  /* Replies list styles */
  .replies-list {
    list-style-type: none;
    padding-left: 20px;
  }
  
  .reply {
    background-color: #e9e9e9;
    padding: 15px;
    margin-top: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-left: 5px solid #e9e9e9; /* Remove the blue gradient */
    position: relative;
  }
  
  .reply::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: none; /* Remove the gradient */
  }
  
  .reply-content {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
  }
  
  .reply p {
    margin: 0;
    padding: 5px 0;
  }
  
  .reply p strong {
    display: block;
    font-size: 1.2rem;
    color: #333;
  }
  
  
  /* Replies list styles */
  .replies-list {
    list-style-type: none;
    padding-left: 20px;
  }
  
  .reply {
    background-color: #e9e9e9;
    padding: 15px;
    margin-top: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* border-left: 5px solid #0056b3; */
    position: relative;
  }
  
  .reply::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    /* background: linear-gradient(to right, #0056b3, #007bff); */
  }
  
  .reply-form {
    margin-top: 15px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .reply-form textarea {
    width: calc(100% - 20px);
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .submit-reply-button {
    background-color: #059669;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .submit-reply-button:hover {
    background-color: #050505;
    transform: scale(1.05);
  }
  
  
  
  
  /* Tags container styles */
  .tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
  }
  

  .tag-add-button {
    margin-top: 5px;
    background-color: #059669;
    color: #fff;
    padding: 5px 10px;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  .tag-add-button:hover {
    background-color: #000000;
    transform: scale(1.05);
  }
  
  .tag {
    background-color: #059669;
    color: #fff;
    padding: 5px 10px;
    border-radius: 15px;
    margin-right: 2px;
  }
  
  .tag button {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
  }

  .remove-tag-button {
    background-color: red; /* Red background */
    color: white; /* White text */
    border: none; /* Remove border */
    border-radius: 50%; /* Circular shape */
    width: 24px; /* Width of the circle */
    height: 24px; /* Height of the circle */
    display: flex; /* Flexbox for centering content */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    font-size: 14px; /* Font size for the cross */
    cursor: pointer; /* Pointer cursor on hover */
    line-height: 1; /* Line height for proper alignment */
  }
  
  .remove-tag-button::before {
    content: '✖'; /* Unicode cross character */
  }  
  
  .tag button:hover {
    color: #ffd700;
  }
  
  .replies-button {
    background-color: #059669;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 8px 16px;
    cursor: pointer;
    /* transition: background-color 0.3s ease, transform 0.3s ease; */
  }

  .replies-button:hover {
    background-color: #000000;
    transform: scale(1.05);
  }

  .custom-dropdown .menu {
    max-height: 200px; /* Adjust height as needed */
    overflow-y: auto;
  }

  .warning-message {
    background-color: #fff4cc; /* Light yellow background */
    color: #856404; /* Dark yellow text */
    border: 1px solid #ffeeba; /* Light yellow border */
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 4px;
    font-size: 12px; /* Small font size */
    text-align: center;
  }
  


/*React paginationm for the discussion form*/
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.pagination a {
  padding: 0.5em 1em;
  margin: 0.25em;
  border: 1px solid #ddd;
  border-radius: 3px;
  cursor: pointer;
}

.pagination .active a {
  background-color: #059669;
  color: white;
  border: 1px solid #059669;
}

.pagination .break-me {
  padding: 0.5em 1em;
}


.flag-button {
  background-color: rgb(202, 70, 70);
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-left: 5px;
}

.flag-button:hover {
  background-color: red;
  transform: scale(1.05);
}

.delete-button {
  background-color: red;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.delete-button:hover {
  background-color: #000000;
  transform: scale(1.05);
}

/* reply of discussion form styles */
.reply-flag-button {
  background-color: rgb(202, 70, 70);
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 2px 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-left: 5px;
}

.reply-flag-button:hover {
  background-color: red;
  transform: scale(1.05);
}

.reply-delete-button {
  background-color: red;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 2px 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.reply-delete-button:hover {
  background-color: #000000;
  transform: scale(1.05);
}


/*analytics styles*/
.analytics {
  padding: 20px;
  background: linear-gradient(135deg, #d0f0c0, #a8d5ba); /* Gradient from Whitish Green to Light Green */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #333; /* Dark text for contrast */
  margin: 10px;
}

.section-title {
  font-size: 24px;
  color: #059669; /* Deep Purple Color */
  margin-bottom: 20px;
}

.analytics-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.summary-card {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.summary-item {
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1;
  text-align: center;
}

.summary-item h3 {
  font-size: 18px;
  color: #059669;
  margin-bottom: 10px;
}

.summary-item p {
  font-size: 16px;
  color: #666;
}

.top-contributors-card {
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.top-contributors-card h3 {
  font-size: 18px;
  color: #059669;
  margin-bottom: 10px;
}

.contributor-list {
  list-style-type: none;
  padding: 0;
}

.contributor-item {
  font-size: 16px;
  color: #444;
  margin-bottom: 8px;
}

.contributor-name {
  font-weight: bold;
}

.contributor-count {
  color: #888;
}

.user-analytics {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}

.user-analytics-left {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.user-analytics-right {
  flex: 1;
}

.analytics-card {
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.analytics-card h3 {
  font-size: 18px;
  color: #059669;
  margin-bottom: 10px;
}

.analytics-card p {
  font-size: 16px;
  color: #666;
}

.circularMeter {
  width: 150px;
  height: 150px;
  margin: 0 auto;
}




/*Circular progress bar styles*/
.circular-progress {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circularMeter {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
}

.admin-badge {
  background-color: #f39c12; /* Example color */
  color: white;
  font-size: 0.8em;
  padding: 2px 6px;
  margin-left: 10px;
  border-radius: 4px;
}
